.progressMainWrapper {
    background: $klimax-grey;
    height: 75%;
    position: absolute;
    top: 175px;
    right: 40px;
    z-index: 1;
    width: 5px;
}

.progressMainStyle {
    background: $klimax-black;
    width: 5px;
}
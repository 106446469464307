.error {
    background: url("../../assets/images/klimax-header-background.svg") center -70px no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;

    &__logo {
        display: flex;
        justify-content: center;

        svg {
            max-width: 600px;
            width: 100%;
        }
    }
    
    &__button {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
}
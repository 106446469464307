.klimax-inspiration {
  background: url("../../assets/images/klimax-background-pattern.svg") center
    center no-repeat;
  height: 100vh;

  .klimax-climatecards {
    background: none;
  }

  &__intro {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dropdown {
      margin-right: 50px;
    }
  }

  &-wrapper {
    background: $klimax-white;
    background: url("../../assets/images/klimax-inspiration-background.svg")
      center center no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.17);
    display: flex;
    flex-direction: column;
    height: 95%;
    justify-content: center;
    left: 50%;
    padding: 100px;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }

  &__close {
    position: absolute;
    right: 30px;
    top: 30px;
    text-decoration: none;
    color: $klimax-orange;
    font-size: 30px;
    transition: all 0.3s;

    &:hover {
      color: $klimax-black !important;
      transition: all 0.3s;

      i {
        color: $klimax-black !important;
      }
    }
  }

  &__title {
    font-family: "Flanders Art Sans Bold";
    font-size: 50px;
    text-align: center;
    margin-bottom: 30px;

    span {
      font-family: "Flanders Art Serif Bold";
      color: $klimax-orange;
    }
  }

  &-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }

  &-card {
    box-shadow: 3px 3px 20px #00000029;
    width: calc(50% - 15px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    height: 170px;
    max-height: 170px;
    position: relative;

    &--topics {
      background: transparent
        linear-gradient(112deg, #287b14 0%, #4f8e40 45%, #66a357 100%) 0% 0%
        no-repeat padding-box;

      .icon-klimax-arrow {
        color: $klimax-card-green;
      }
    }

    &--goals {
      background: transparent
        linear-gradient(110deg, #e99349 0%, #f4ce37 54%, #f2441a 100%) 0% 0%
        no-repeat padding-box;

      .icon-klimax-arrow {
        color: $klimax-card-orange;
      }
    }

    &--themes {
      background: #3e76da 0% 0% no-repeat padding-box;

      .icon-klimax-arrow {
        color: $klimax-card-blue;
      }
    }

    &--methods {
      background: transparent
        linear-gradient(110deg, #e99349 0%, #f4ce37 54%, #f2441a 100%) 0% 0%
        no-repeat padding-box;

      .icon-klimax-arrow {
        color: $klimax-card-orange;
      }
    }

    &__info {
      padding: 50px 170px 50px 50px;
      width: 100%;
      z-index: 999;
    }

    &__title {
      font-family: "Flanders Art Sans Bold";
      color: $klimax-white;
      font-size: 45px;
      margin-bottom: 0;
      hyphens: auto;
      line-height: 47px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &__subtitle {
      color: $klimax-white;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 22px;
      line-height: 26px;
    }

    &__chevron {
      align-items: center;
      bottom: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.3s;
      width: 170px;

      i {
        transform: rotate(-90deg);
        font-size: 50px;
        display: flex;
        z-index: 1;
        padding-top: 30px;
      }

      &:after {
        background: url("../../assets/images/klimax-tile-before.svg");
        background-repeat: no-repeat;
        background-size: cover;
        bottom: -1px;
        content: "";
        display: block;
        height: 102%;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 100%;
      }
    }

    &:hover {
      .klimax-inspiration-card__chevron {
        width: 190px;
        transition: all 0.3s;
      }
    }
  }

  &--picker {
    .klimax-inspiration-wrapper {
      justify-content: flex-start;
      padding: 40px 40px 20px 80px;
      overflow: hidden;

      > div {
        height: 100%;
        overflow: clip;
      }
    }

    .klimax-inspiration__title {
      text-align: left;
    }

    .klimax-climatecards {
      overflow-y: scroll;
      height: 100%;
      position: absolute;
      padding: 0px 50px 200px 0px;
    }
  }

  &--themes {
    .klimax-inspiration__title {
      color: $klimax-card-blue;
    }

    .klimax-inspiration-wrapper {
      background: url("../../assets/images/klimax-inspiration-background-detail.svg")
        center center no-repeat;
      background-size: cover;
    }

    .klimax-climatecards {
      &::-webkit-scrollbar-track {
        background: rgba(62, 118, 218, 0.23);
        position: absolute;
        top: 50px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $klimax-card-blue;
      }
    }
  }

  &--topics {
    .klimax-inspiration__title {
      color: $klimax-card-green;
    }

    .klimax-inspiration-wrapper {
      background: url("../../assets/images/klimax-inspiration-background-detail.svg")
        center center no-repeat;
      background-size: cover;
    }

    .klimax-climatecards {
      &::-webkit-scrollbar-track {
        background: rgba(79, 142, 64, 0.23);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $klimax-card-green;
      }
    }
  }

  &--goals {
    .klimax-inspiration-wrapper {
      background: url("../../assets/images/klimax-inspiration-background-detail.svg")
        center center no-repeat;
      background-size: cover;
    }

    .klimax-inspiration-results {
      .klimax-goal {
        width: 100%;
      }
    }

    .klimax-climatecards {
      display: block;
    }
  }

  &--methods {
    .klimax-inspiration-wrapper {
      background: url("../../assets/images/klimax-inspiration-background-detail.svg")
        center center no-repeat;
      background-size: cover;
    }

    .klimax-climatecards {
      &::-webkit-scrollbar-thumb {
        background-color: $klimax-black;
      }
    }
  }

  &__back {
    position: absolute;
    left: 25px;
    top: 46px;

    i {
      font-size: 44px;
      transform: rotate(90deg);
      display: block;
      color: $klimax-black;
      height: 44px;
      width: 44px;
    }
  }

  &-results {
    display: flex;
    height: 100%;
    // margin-bottom: 540px;
    overflow: hidden;

    .klimax-toc {
      -ms-overflow-style: none;
      /* IE and Edge */
      list-style: none;
      margin-right: 50px;
      min-width: 150px;
      overflow-y: scroll;
      padding-bottom: 50px;
      padding: 0;
      scrollbar-width: none;
      /* Firefox */
      width: 150px;

      span {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        font-size: 18px;
        line-height: 20px;
        font-family: "Flanders Art Sans Bold";
        text-transform: lowercase;
        margin-bottom: 20px;

        &--blue {
          > a {
            color: $klimax-card-blue;
          }
        }

        &--topic {
          &-lightgreen {
            > a {
              color: $klimax-topic-lightgreen;
            }
          }

          &-midgreen {
            > a {
              color: $klimax-topic-midgreen;
            }
          }

          &-darkgreen {
            > a {
              color: $klimax-topic-darkgreen;
            }
          }
        }

        &--method {
          &-yellow {
            a {
              color: $klimax-card-yellow;
            }
          }

          &-orange {
            a {
              color: $klimax-card-orange;
            }
          }

          &-red {
            a {
              color: $klimax-method-red;
            }
          }
        }
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;

      // &:before {
      //     background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #FFF 100%);
      //     border-radius: 0px 0px 3px 3px;
      //     content: '';
      //     display: block;
      //     height: 30px;
      //     position: absolute;
      //     top: -50px;
      //     transition: .5s all;
      //     width: 100%;
      //     margin-left: -45px;
      //     z-index: 1;
      //     right: 0;
      // }
    }

    .klimax-climatecards {
      margin: 0;
      width: 100%;
      scroll-behavior: smooth !important;

      .klimax-climatecard {
        width: calc(50% - 8px);
      }
    }
  }

  .klimax-goals {
    .klimax-goal {
      .accordion-item {
        position: relative;
        margin-bottom: 12px;

        &--orange {
          .accordion-header {
            background-color: $klimax-method-orange;
          }

          .accordion-body > ul > li:before {
            background: $klimax-method-orange !important;
          }
        }

        &--yellow {
          .accordion-header {
            background-color: $klimax-method-yellow;
          }

          .accordion-button {
            color: $klimax-black;
          }

          .accordion-body > ul > li:before {
            background: $klimax-method-yellow !important;
          }
        }

        &--red {
          .accordion-header {
            background-color: $klimax-method-red;
          }

          // .accordion-button {
          //   color: $klimax-black;
          // }

          .accordion-body > ul > li:before {
            background: $klimax-method-red !important;
          }
        }

        .accordion-body {
          padding: 15px 30px;
          > ul {
            list-style: none;
            padding-left: 20px;
            margin: 10px 0 0 0;
            > li {
              padding-left: 15px;
              margin-bottom: 15px;
              &:before {
                display: inline-block;
                content: "";
                background: $klimax-black;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                margin-right: 10px;
                margin-left: -22px;
                position: relative;
                left: -15px;
              }
            }
          }
        }
      }

      .check {
        position: absolute;
        top: 10px;
        z-index: 3;
        left: 25px;

        .form-check-input {
          width: 20px;
          height: 20px;
          border-radius: 50%;

          &:hover {
            background-color: $klimax-grey;
            cursor: pointer;
          }

          :focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
            outline-color: $klimax-orange;
            outline-offset: 4px;
          }

          &:checked {
            background-color: $klimax-black;
            border-color: $klimax-black;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      &__item {
        .accordion-button {
          align-items: flex-start;
          background-color: transparent;
          border-radius: 0 !important;
          color: $klimax-white;
          display: flex;
          font-family: "Flanders Art Sans Bold";
          font-size: 18px;
          padding: 13px 150px 13px 65px;
          background-size: 85px;
          text-transform: lowercase;
          position: relative;
          overflow: hidden;

          div {
            font-family: "Flanders Art Sans Bold";
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            span {
              font-family: "Flanders Art Sans Regular";
            }
          }

          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }

          &:before {
            content: "";
            width: 150px;
            height: 100px;
            -webkit-transform: skew(-20deg);
            -moz-transform: skew(-20deg);
            -o-transform: skew(-20deg);
            background: $klimax-grey;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: -60px;
            transition: all 0.3s;
          }

          &:after {
            content: "\67";
            font-family: "klimax" !important;
            background-image: none !important;
            color: $klimax-black;
            font-size: 20px;
            position: absolute;
            right: 20px;
          }

          &:hover {
            &:before {
              right: -30px;
            }
          }
        }

        .form-check-input {
          height: 20px;
          width: 20px;
          border: none;
          margin: 0 10px 0 0;
        }
      }
    }
  }

  .klimax-radio-buttons {
    padding-right: 50px;

    fieldset {
      padding: 15px 26px;
      background: #f6f6f3;
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        display: flex;
        align-items: center;

        &:first-child {
          margin-right: 20px;
        }
      }

      .form-check-input {
        height: 20px;
        width: 20px;
        min-width: 20px;
        min-height: 20px;

        &:hover {
          cursor: pointer;
        }
      }

      label {
        font-family: "Flanders Art Sans Bold";
        margin-left: 8px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .klimax-goals-buttons {
    display: flex;
    flex-direction: row;
    margin: 30px 0;

    .klimax-button--inverted {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .klimax-inspiration-wrapper {
    padding: 100px 30px;
  }
}

@media screen and (max-width: 1200px) {
  .klimax-inspiration__intro {
    flex-direction: column;
    align-items: baseline;

    .klimax-radio-buttons {
      padding-right: 50px;
      width: 100%;

      fieldset {
        justify-content: flex-start;
      }
    }
  }

  .klimax-inspiration-card {
    &__title {
      font-size: 32px;
      line-height: 36px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 992px) {
  .klimax-inspiration-card {
    width: 100%;
  }

  .klimax-inspiration__intro {
    .klimax-radio-buttons {
      fieldset {
        flex-direction: column;
        align-items: baseline;
      }
    }
  }

  .klimax-inspiration--picker {
    .klimax-inspiration-wrapper {
      justify-content: center;
    }
  }

  .klimax-inspiration-wrapper {
    overflow-y: scroll;
    justify-content: flex-start;

    .container {
      max-width: 90%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .klimax-climatecards.klimax-goals {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .klimax-inspiration .klimax-goals .klimax-goal__item .accordion-button {
    padding-right: 120px;
  }

  .klimax-inspiration .klimax-radio-buttons {
    padding-right: 0;

    fieldset > div {
      align-items: flex-start;
    }
  }

  .klimax-inspiration .klimax-goals-buttons {
    flex-direction: column;

    .klimax-button,
    .klimax-button--inverted {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .klimax-radio-buttons {
    // display: none;
  }

  .klimax-goal {
    .accordion-button {
      background: none !important;

      span {
        display: none !important;
      }
    }
  }

  .klimax-goals {
    padding: 0px 0px 200px 0px !important;
    position: relative;
  }

  .klimax-inspiration-cards {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .klimax-inspiration-card {
    height: 100%;

    &__info {
      padding: 20px 100px 20px 20px;
    }

    &__chevron {
      width: 100px;

      i {
        font-size: 40px;
      }
    }

    &__title {
      font-size: 22px;
    }

    &__subtitle {
      display: none;
    }

    &:hover .klimax-inspiration-card__chevron {
      width: 120px;
    }
  }

  .klimax-inspiration__title {
    font-size: 32px;
    margin-top: 11px;
    padding-left: 40px;
  }

  .klimax-inspiration--picker {
    .klimax-inspiration-wrapper {
      height: 100%;
      width: 100%;
      padding: 20px !important;

      .dropdown {
        display: none;
      }
    }
  }

  .klimax-inspiration-wrapper {
    height: 100%;
    width: 100%;
    padding: 100px 20px 20px 20px !important;
  }

  .klimax-toc {
    display: none;
  }

  .klimax-inspiration__back {
    left: 25px;
    top: 35px;
    display: flex;
    align-items: center;
    color: $klimax-black;

    i {
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }

  .klimax-inspiration-results {
    height: 96%;
  }
}

@media screen and (max-width: 550px) {
  .klimax-climatecards {
    padding: 0 0 200px 0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .klimax-inspiration .klimax-goals .klimax-goal__item .accordion-button {
    font-size: 14px;
    padding-left: 40px;
  }

  .klimax-inspiration .klimax-goals .klimax-goal .check {
    left: 10px;
    top: 7px;
  }
}

.klimax-footer {
    background: url('../../assets/images/klimax-footer-background.svg') center top no-repeat;
    background-size: cover;
    padding: 250px 0 0 0;
    height: 400px;
    margin-top: -200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__logos {
        margin-bottom: 80px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 50px;

        a {
            img {
                height: 80px;
            }
        }
        
    }

    &-wrapper {
        background: $klimax-white;
        width: 100%;
        text-align: center;
        padding: 20px 0;

        .klimax-footer__cooperation {
            a {
                color: $klimax-orange;
                text-decoration: underline !important;
            }
        }

        .klimax-footer__copyright {
            color: #969696;
            margin-top: 10px;
        }
    }

    &-cookies {
        background: #F6F6F3;
        width: 100%;
        text-align: center;
        padding: 10px 0;

        a {
            color: #969696 !important;
            text-decoration: underline !important;
            margin: 0 15px;
            transition: all .3s;

            &:hover {
                text-decoration: none !important;
                transition: all .3s;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .klimax-footer {
        height: 700px;

        &-wrapper {
            padding: 20px;
        }

        &__cooperation {
            padding: 0 30px;
            line-height: 18px;
        }

        &__logos {
            flex-direction: column;
        }
    }
}
#mobile-header {
  display: none;
}

.klimax-header {
  background: url("../../assets/images/klimax-header-background.svg") center
    bottom no-repeat;
  background-size: cover;
  padding: 20px 0 10px 0;

  &-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // height: 222px;
    width: 100%;

    div {
      svg {
        width: 100%;
        max-width: 600px;
        height: 100%;
      }

      &.selected-logo {
        display: none;
      }
    }

    &__baseline {
      path {
        fill: $klimax-orange;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -100px;

    .klimax-button {
      &:last-child {
        margin-right: 0;
      }

      &--inverted {
        i {
          margin: 0;
        }
      }
    }

    &-left,
    &-right {
      display: flex;
      align-items: center;
    }

    &-right {
      justify-content: flex-end;
    }
  }

  &__klimax {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 200px;
    padding-top: 10px;

    svg {
      -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.38);
      box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.38);
      border-radius: 50%;
      width: 150px;
    }

    path {
      //   opacity: 0.2;
      transition: all 0.3s;

      &:hover {
        opacity: 1;
        transition: all 0.3s;
      }

      //   #theme1,
      //   #theme2,
      //   #theme3,
      //   #werkvorm_kritisch-denken_geel,
      //   #werkvorm_communicatie_rood,
      //   #werkvorm_actiegericht_oranje,
      //   #doel_kritisch-denken_geel,
      //   #doel_communicatie_rood,
      //   #doel_actiegericht_oranje,
      //   #topic_oorzaak,
      //   #topic_oplossing,
      //   #topic_gevolg {
      .pinned {
        opacity: 1 !important;
        transition: all 0.3s;
      }

      //   }
    }
  }

  .klimax-switch {
    background: $klimax-white;
    width: 162px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    font-family: "Flanders Art Sans Medium";
    margin-bottom: 20px;

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 17px;
      margin-right: 8px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $klimax-white;
      border: 1px solid $klimax-black;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      height: 18px;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 3px;
      bottom: 2px;
      background-color: $klimax-black;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input.checked + .slider {
      background-color: $klimax-black;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input.checked + .slider:before {
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
      background: $klimax-white;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
.klimax-header--met-thema.klimax-header--met-werkvorm1of2.klimax-header--met-werkvorm3of4.klimax-header--met-werkvorm5of6.klimax-header--met-doelen1of2.klimax-header--met-doelen3of4.klimax-header--met-doelen5of6.klimax-header--met-topics {
  .klimax-header-logo {
    div {
      &.logo {
        display: none;
      }

      &.selected-logo {
        display: block;

        svg {
          .st1 {
            fill: $klimax-orange;
          }
          .st3 {
            fill: $klimax-method-red;
          }
          .st4 {
            fill: $klimax-card-blue;
          }
          .st7 {
            fill: $klimax-card-yellow;
          }
          .st5 {
            fill: $klimax-card-darkgreen;
          }
          .st6 {
            fill: $klimax-card-lightgreen;
          }
        }
      }
    }

    &:after {
      content: "";
      background: url("../../assets/images/klimax-logo--selected-after.svg")
        center center no-repeat;
      height: 222px;
      width: 100%;
      position: absolute;
    }

    &__baseline {
      path {
        fill: $klimax-orange;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .klimax-header {
    &__klimax {
      margin: 0 auto 30px auto;
    }

    &__actions {
      top: -40px;
    }
  }
}

@media screen and (max-width: 992px) {
  #header {
    display: none;
  }

  #mobile-header {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .klimax-switch {
    display: none !important;
  }

  .klimax-header--selected .klimax-header-logo:after {
    display: none;
  }

  .klimax-header {
    padding: 10px 0 10px 0;

    &-logo__baseline {
      display: none;
    }
  }

  .klimax-header-logo {
    height: 160px;
  }
}

.klimax-mobilemenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: url('../../assets/images/mobilemenu-bg.png') center bottom no-repeat;
    height: 500px;
    transform: translateY(-100%);
    transition: transform 0.5s;
    z-index: 10;
    position: fixed;

    &--open {
        transform: translateY(0);
        transition: transform 0.5s;
    }

    .klimax-mobilemenu__close {
        text-align: right;
        position: fixed;
        right: 20px;
        top: 30px;
        
        i {
            color: $klimax-white;
            font-size: 24px;
        }
    }

    &-wrapper {
        padding: 85px 0 0 0;
    }

    .klimax-button {
        box-shadow: none;
    }
}
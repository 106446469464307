/* 

Exit transition
showFront = true -> false
front -> back

Enter transition
showFront = false -> true
back -> front

*/

.flip-exit {
    transform: rotateY(0deg);
}

.flip-exit-active {
    transform: rotateY(180deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(.57, .21, .69, 1.25);
}

.flip-exit-done {
    transform: rotateY(180deg);
    transition: transform 300ms;
}

.flip-enter {
    transform: rotateY(180deg);
    transition: transform 300ms;
}

.flip-enter-active {
    transform: rotateY(0deg);
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(.57, .21, .69, 1.25);
}

.flip-enter-done {
    transform: rotateY(0deg);
    transition: transform 300ms;
}
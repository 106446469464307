.klimax-sidebar {
  background: $klimax-white;
  bottom: 0;
  position: fixed;
  top: 0;
  width: 50%;
  z-index: 100;
  box-shadow: 0px 3px 25px #00000089;
  transform: translateX(-110%);
  transition: transform 0.5s;
  overflow: hidden;

  &--open {
    transform: translateX(0);
    transition: transform 0.5s;
  }

  &__close {
    position: fixed;
    right: 20px;
    top: 30px;

    i {
      font-size: 24px;
    }
  }

  &__title {
    color: $klimax-orange;
    font-family: "Flanders Art Sans Bold";
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .klimax-legende {
    background: $klimax-grey;
    padding: 140px 30px 20px 30px;
    margin-bottom: 50px;
    margin-top: 150px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 80px;
      width: 100%;
      background: url(../../assets/images/legende-background.png) center bottom
        no-repeat;
      left: 0;
      top: -80px;
      background-size: contain;
    }

    svg {
      position: absolute;
      left: calc(50% - 115px);
      top: -120px;
      width: 230px;
      height: 230px;
    }

    span {
      font-family: "Flanders Art Sans Bold";

      &.highlight-blue {
        color: $klimax-card-blue;
      }

      &.highlight-green {
        color: $klimax-card-green;
      }

      &.highlight-orange {
        color: $klimax-orange;
      }

      &.highlight-yellow {
        color: $klimax-card-yellow;
      }

      &.highlight-red {
        color: $klimax-method-red;
      }
    }
  }

  h3 {
    font-family: "Flanders Art Sans Bold";
    color: $klimax-orange;
    margin: 30px 0;
  }

  p {
    font-size: 18px;
  }

  &-wrapper {
    height: 100%;
    padding: 100px 50px 0px 50px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &:before {
      content: "";
      background: url("../../assets/images/PatternBlock_hl2.svg") center top
        no-repeat;
      height: 300px;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      z-index: -1;
      background-size: cover;
    }
  }

  .nav-tabs {
    background: $klimax-white;
    border: none;
    width: fit-content;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.38);
    overflow: hidden;

    .nav-item {
      &:first-child {
        .nav-link {
          margin-left: 0;
          padding: 11px 10px 11px 18px;
        }
      }

      &:last-child {
        .nav-link {
          margin-right: 0;
          padding: 11px 18px 11px 10px;
        }
      }
    }

    .nav-link {
      border-radius: 0;
      border: none;
      color: $klimax-black;
      font-family: "Flanders Art Sans Bold";
      font-size: 27px;
      height: 50px;
      line-height: 27px;
      padding: 11px 10px;
      position: relative;
      margin: 0 10px;

      &:before {
        content: "";
        width: calc(100% + 20px);
        height: 50px;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        overflow: hidden;
        position: absolute;
        top: 0;
        left: -10px;
        transition: all 0.3s;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          margin-left: 8px;
          display: flex;
        }

        span {
        }
      }

      &.active {
        color: $klimax-white;

        div {
          span,
          i {
            color: $klimax-white;
            z-index: 1;
          }
        }

        &:before {
          background: $klimax-orange;
        }

        // &:before {
        //     content: "";
        //     position: absolute;
        //     background: url('../../assets/images/active-item-before.svg');
        //     height: 50px;
        //     width: 22px;
        //     left: -20px;
        //     top: 0;
        // }

        // &:after {
        //     content: "";
        //     position: absolute;
        //     background: url('../../assets/images/active-item-after.svg');
        //     height: 50px;
        //     width: 20px;
        //     right: -20px;
        //     top: 0;
        // }
      }
    }
  }

  .tab-content {
    margin-top: 50px;

    &:after {
        content: "";
        background: url("../../assets/images/Footer_PatternBlock_hl2.svg") center bottom no-repeat;
        background-size: cover;
        height: 350px;
        position: relative;
        display: block;
        width: calc(100% + 100px);
        left: -50px;
        z-index: -1;
        bottom: 0;
    }

    .tab-pane {
      &.active {
        .accordion-header {
          .accordion-button {
            font-family: "Flanders Art Sans Bold";
            font-size: 22px;
            color: $klimax-white;
            padding: 13px 25px;
            box-shadow: none;

            span {
                font-size: 15px;
            }
          }
        }
      }
    }
  }

  .accordion-item {
    margin-bottom: 30px;
    border-radius: 0;
    border: 0;
  }

  .accordion-header {
    background: $klimax-orange;
  }

  .accordion-body {
    border: 1px solid $klimax-orange;
    padding: 40px 30px;
    border-top: none;
    
    h3 {
        margin-top: 0;
    }

    ol {
      counter-reset: custom;
      list-style-type: none;

      li {
        font-family: "Flanders Art Sans Regular";
        font-size: 18px;
        margin-bottom: 22px;
        position: relative;

        b {
          font-family: "Flanders Art Sans Bold";
        }

        &:before {
          content: "" counter(custom) "";
          counter-increment: custom;
          position: absolute;
          left: -30px;
          font-family: "Flanders Art Sans Bold";
        }
      }
    }

    .klimax-questions {
      background: $klimax-grey;
      padding: 30px 40px;

      &-list {
        margin: 0;
        list-style: none;

        &__item {
          font-size: 18px;
          font-family: "Flanders Art Sans Bold";
          margin-bottom: 20px;
          position: relative;

          &:last-child {
            margin: 0;
          }

          &:before {
            position: absolute;
            content: " ";
            display: block;
            height: 12px;
            width: 12px;
            background: $klimax-orange;
            left: -30px;
            border-radius: 50%;
            top: 6px;
          }
        }
      }
    }
  }

  .accordion-button {
    align-items: center;
    background: url("../../assets/images/accordion-item-after.svg") center right
      no-repeat;
    background-color: transparent;
    border-radius: 0 !important;
    color: $klimax-white;
    display: flex;
    font-family: "Flanders Art Sans Bold";
    font-size: 27px;
    padding: 13px 25px;
    background-size: 85px;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &:after {
      content: "\67";
      font-family: "klimax" !important;
      background-image: none !important;
      color: $klimax-black;
      font-size: 20px;
    }
  }

  .accordion-button:not(.collapsed) {
    background-color: $klimax-orange;
  }
}

@media screen and (max-width: 1200px) {
  .klimax-sidebar {
    width: 62%;
  }
}

@media screen and (max-width: 992px) {
  .klimax-sidebar {
    width: 80%;
  }

  .accordion-button {
    span {
        display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .klimax-sidebar {
    width: 100%;

    &__close {
      position: absolute;
    }

    &-wrapper {
      padding: 100px 30px 50px 30px;
    }

    .accordion-item {
      margin-bottom: 15px;
    }

    .nav-tabs {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .nav-link {
      font-size: 20px !important;
      width: 100%;

      &:before {
        -webkit-transform: skew(-0deg) !important;
        -moz-transform: skew(-0deg) !important;
        -o-transform: skew(-0deg) !important;
      }
    }
  }
}

@import './scss/variables.scss';
@import './scss/components.scss';
@import './scss/pages.scss';

*,
::after,
::before {
    box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html,
body {
  height: 100%;
}

html{
    scroll-behavior: smooth !important;
}

body {
  margin: 0;
  font-family: 'Flanders Art Sans Regular' !important;
}

a {
  color: $klimax-black;
  text-decoration: none !important;

  i {
    color: $klimax-black;
  }
}

li {
  font-family: 'Flanders Art Sans Regular';
}

b {
    font-family: 'Flanders Art Sans Bold';
}

p {
    margin-bottom: 30px;
}

.container {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    position: relative;
    width: 20px;
    height: 20px;
    transition: all .3s;

    &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background: #d96422;
        position: absolute;
        left: 3px;
        top: 3px;
    }

    &:checked {
        background: $klimax-white !important;
        border-color: $klimax-orange;

        &::before {
            transform: scale(1);
        }
    }

    &:active,
    &:focus,
    &:hover {
        outline: none;
        box-shadow: none;
        border-color: $klimax-orange;
        transition: all .3s;
    }
}

.high-contrast {
    .klimax-climatecard,
    .klimax-climatecard-content,
    .klimax-climatecard-content:before,
    .klimax-climatecard__face--back,
    .klimax-inspiration-card,
    .accordion-header {
        background: $klimax-black !important;
    }

    .accordion-button {
        color: $klimax-white !important;
    }

    .klimax-inspiration-card .icon-klimax-arrow {
        color: $klimax-black;
    }

    .klimax-climatecard--placeholder {
        background: #F4F4F4 !important;
    }

    .klimax-climatecard--add {
        .klimax-climatecard-content {
            background: $klimax-white !important;
        }

        .icon-klimax-plus {
            color: $klimax-black;
        }
    }

    .klimax-climatecard__face--back:after {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.95) 80%) !important;
    }
}

.divider-line {
    background: $klimax-grey;
    height: 2px;
    width: 100%;
    margin: 30px 0;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: $klimax-orange;
    outline-offset: 4px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

/* FONTS: */
@font-face {
  font-family: "Flanders Art Sans Regular";
  src: url("../assets/fonts/FlandersArtSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Flanders Art Sans Medium";
  src: url("../assets/fonts/FlandersArtSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Flanders Art Sans Bold";
  src: url("../assets/fonts/FlandersArtSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "Flanders Art Serif Bold";
  src: url("../assets/fonts/FlandersArtSerif-Bold.woff") format("woff");
}

/* Colors: */
$klimax-orange: #d96422;
$klimax-white: #ffffff;
$klimax-black: #000000;
$klimax-grey: #f4f4f4;

$klimax-card-blue: #3e76da;
$klimax-card-fluo-blue: #32c9f4;
$klimax-card-yellow: #f4cc27;
$klimax-card-orange: #f78e34;
$klimax-card-darkgreen: #287b14;
$klimax-card-green: #4f8e40;
$klimax-card-lightgreen: #66a357;
$klimax-card-black: #000000;

$klimax-method-yellow: #f4cc27;
$klimax-method-orange: #f78e34;
$klimax-method-red: #f2441a;

$klimax-topic-lightgreen: #66a357;
$klimax-topic-midgreen: #4f8e40;
$klimax-topic-darkgreen: #287b14;

/* breakpoints: */
$breakpoint-xxl: 1400px;
$breakpoint-xl: 1200px;
$breakpoint-l: 1024px;
$breakpoint-m: 992px;
$breakpoint-s: 768px;
$breakpoint-xs: 576px;

.klimax-button {
    align-items: center;
    background: $klimax-white;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    color: $klimax-black;
    display: flex;
    flex-wrap: nowrap;
    font-family: 'Flanders Art Sans Bold';
    font-size: 16px;
    justify-content: center;
    margin-bottom: 10px;
    margin: 0 10px 10px 0;
    padding: 10px 14px;
    transition: all .3s;

    &--inline {
        display: inline-flex;
    }

    &--inverted {
        background: $klimax-orange;
        color: $klimax-white;

        i {
            color: $klimax-white;
        }
    }

    i {
        position: relative;
        top: 3px;
        color: $klimax-orange;
        margin-right: 8px;
    }

    &:hover {
        color: $klimax-white;
        background: $klimax-orange;
        transition: all .3s;

        i {
            color: $klimax-white;
        }
    }

    &--inverted {
        background: $klimax-orange;
        color: $klimax-white;

        i {
            color: $klimax-white;
        }

        &:hover {
            background: $klimax-white;
            color: $klimax-black;

            i {
                color: $klimax-orange;
            }
        }
    }
}

.dropdown-toggle {
    background: $klimax-orange !important;
    border: none !important;

    &::after {
        border: none !important;
        content: "\67" !important;
        font-family: "klimax" !important;
        font-size: 14px;
        margin-left: 8px !important;
        position: relative;
        vertical-align: middle !important;
        transform: rotate(0deg);
        transition: all .3s;
    }

    &.show {
        &:after {
            transform: rotate(180deg);
            transition: all .3s;
        }
    }
}

.dropdown-item {
    &:active {
        background-color: $klimax-grey !important;
        color: $klimax-black !important;
    }
}
.klimax-pin {
  background: $klimax-white;
  box-shadow: 0px 0px 30px #00000029;
  height: auto;
  left: calc(50% - 300px);
  padding: 30px 50px;
  position: absolute;
  top: 0;
  width: 600px;
  z-index: 1000;
  transition: transform 0.5s;
  transform: translateY(-120%);

  &--open {
    transition: transform 0.5s;
    transform: translateY(0%);
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 15px;
  }

  &-dialog {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    svg {
      height: 100px;
      width: 100px;
      margin: 0 10px 0 0;
    }

    > i {
      font-size: 80px;
      color: $klimax-orange;
    }
  }

  &__title {
    font-size: 18px;
    text-align: left;
    margin: 0;

    b {
      font-family: "Flanders Art Sans Bold";
    }
  }

  &-buttons {
    display: flex;

    .klimax-button {
      margin: 0 0 0 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .klimax-pin {
    width: 100%;
    left: 0;

    &-dialog {
      flex-direction: column;

      svg {
        margin: 0;
      }

      > div {
        flex-direction: column;
        margin-bottom: 10px;
      }
    }
  }

  .klimax-modal-dialog {
    padding: 30px 60px;
  }

  .klimax-modal-buttons {
    flex-direction: column;

    .klimax-button {
      margin-right: 0;
    }
  }
}

.klimax-modal {
    bottom: 0;
    left: 0;
    outline: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999999;

    &-dialog {
        align-items: center;
        background: $klimax-white;
        box-shadow: 0px 0px 30px #00000029;
        display: flex;
        flex-direction: column;
        height: 360px;
        justify-content: center;
        left: calc(50% - 250px);
        padding: 50px 30px 30px 30px;
        position: absolute;
        top: calc(50% - 180px);
        width: 500px;
        z-index: 1000;

        img {
            width: 100px;
        }

        > i {
            font-size: 80px;
            color: $klimax-orange;
        }
    }

    &-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background-color: $klimax-white;
        opacity: .8;
    }

    &__title {
        margin: 15px 0 40px 0;
        font-size: 22px;
        text-align: center;

        b {
            font-family: 'Flanders Art Sans Bold';
        }
    }

    &-buttons {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .klimax-modal-dialog {
        padding: 30px 60px;
    }

    .klimax-modal-buttons {
        flex-direction: column;

        .klimax-button {
            margin-right: 0;
        }
    }
}

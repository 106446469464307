.klimax-box {
    padding-bottom: 50px;

    &-wrapper {
        background: $klimax-white;
        width: 90%;
        max-width: 1320px;
        padding: 50px 50px 50px 50px;
        justify-content: flex-start;
        margin: 80px auto;
        box-shadow: 0px 0px 30px #00000029;
        overflow: scroll;
        position: relative;

        > .klimax-button {
            position: absolute;
            right: 15px;
            top: 30px;
        }

        &:before {
            content: "";
            background: url("../../assets/images/klimax-header-background.svg") center top no-repeat;
            background-size: cover;
            position: fixed;
            left: 0;
            right: 0;
            height: 500px;
            top: 0;
            z-index: -1;
        }

        h1 {
            color: $klimax-orange;
            font-family: "Flanders Art Sans Bold";
            font-size: 32px;
            line-height: 36px;
        }

        h2 {
            color: $klimax-black;
            font-family: "Flanders Art Sans Bold";
            font-size: 26px;
            line-height: 30px;
            margin-top: 30px;
        }

        h3 {
            font-family: "Flanders Art Sans Bold";
            color: $klimax-orange;
            font-size: 20px;
            line-height: 24px;
            margin: 20px 0 0 0;
        }

        .vl-properties__label {
            font-family: "Flanders Art Sans Bold"; 
            color: $klimax-orange;
        }

        .vl-properties__data {
            margin-bottom: 15px;
        }

        .table {
            margin-top: 30px;

            .thead-light {
                background: $klimax-grey;

                th {
                    font-family: "Flanders Art Sans Bold";
                }
            }
        }
    }

    .container {
        width: 75%;
        float: left;

        a {
            color: $klimax-black;
            font-family: "Flanders Art Sans Bold";

            &:hover {
                color: $klimax-black;
                text-decoration: underline !important;
            }
        }

        .klimax-button {
            &:hover {
                text-decoration: none !important;
                color: $klimax-white;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .klimax-box {
        .container {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .klimax-box {
        padding: 0;
        &-wrapper {
            width: 100%;
            margin: 0;
            padding: 100px 50px 50px 50px;
        }
    }
}
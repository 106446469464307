@import "./flip.scss";

.klimax-climatecards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
    margin: 0;
    margin: 30px 0;
    padding: 0;

    .klimax-dropdown {
        display: none;
    }

    .klimax-unpin {
        display: flex;
    }

    &-wrapper {
        margin: 0 auto;
    }

    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $klimax-orange;
    }
}

.klimax-climatecard {
    // background: $klimax-orange;
    box-shadow: 4px 4px 10px #00000055;
    cursor: default;
    user-select: none;
    display: flex;
    height: 100%;
    max-height: 330px;
    height: 330px;
    position: relative;
    transform-style: preserve-3d;
    width: calc(33.333333% - 10px);

    &__face {
        position: absolute;
        width: 100%;
        height: 100%;
        color: white;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        z-index: 1;

        &--front {
            background: url("../../assets/images/klimax-card-thumb.png") center top no-repeat;
            background-size: contain;
        }

        &--back {
            height: 100%;
            transform: rotateY(180deg);
            padding: 30px 60px 0px 30px;
            z-index: 2;

            &:after {
                content: " ";
                position: absolute;
                left: 0;
                right: 0;
                height: 35px;
                bottom: 0;
                background: linear-gradient(to bottom,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0.95) 80%);
                z-index: 1;
            }

            .klimax-climatecard-title {
                margin: 0 0 15px 0;
            }

            .klimax-climatecard-wrapper {
                overflow: scroll;
                -ms-overflow-style: none;

                /* IE and Edge */
                scrollbar-width: none;
                padding-bottom: 30px;

                /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }

                .klimax-climatecard-ages {
                    display: flex;
                    flex-direction: row;
                    gap: 5px 10px;
                    flex-wrap: wrap;
                    margin-bottom: 10px;

                    li {
                        // display: flex;
                        align-items: center;
                        background-color: $klimax-black;
                        background: $klimax-white;
                        color: $klimax-black;
                        display: flex;
                        flex-shrink: 0;
                        font-family: "Flanders Art Sans Bold";
                        font-size: 13px;
                        height: 20px;
                        justify-content: center;
                        list-style-type: none !important;
                        margin: 0;
                        padding: 0 5px 0 5px;
                        text-transform: lowercase;
                        width: fit-content;

                        &::before {
                            content: "";
                            display: none;
                        }

                        &:after {
                            content: "";
                            background: url("../../assets/images/klimax-label.svg") center center no-repeat;
                            height: 20px;
                            width: 15px;
                            display: block;
                            position: absolute;
                            right: -10px;
                            top: 0px;
                        }
                    }
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;

            &.external-links {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding-left: 25px;

                    &:before {
                        font-family: "klimax" !important;
                        content: "\70";
                        background: none;
                        position: relative;
                        top: 2px;
                        left: -5px;
                    }

                    a {
                        text-decoration: none;
                        color: $klimax-white !important;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            li {
                display: block;
                line-height: 18px;
                margin-bottom: 15px;
                position: relative;
                padding-left: 20px;

                // a {
                //     color: $klimax-white;

                //     &:hover {
                //         text-decoration: underline !important;
                //     }
                // }

                >div {
                    display: inline-block;
                }

                span {
                    font-family: "Flanders Art Sans Bold";
                    width: 90px;
                    margin-right: 8px;
                }

                &:before {
                    display: inline-block;
                    content: "";
                    background: $klimax-white;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-left: -20px;
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-awesome-counter;
            margin: 0;
            padding: 0;
            padding-left: 20px;
        }

        ol li {
            counter-increment: my-awesome-counter;
            position: relative;
            margin-bottom: 10px;
        }

        ol li::before {
            content: counter(my-awesome-counter) " ";
            color: $klimax-white;
            font-family: 'Flanders Art Sans Bold';
            position: absolute;
            left: -20px;
        }
    }

    &--add {
        transition: all 0.5s;

        .klimax-climatecard-content {
            background: $klimax-white;
            border: 2px solid $klimax-orange;
            border-style: dashed;
            cursor: pointer;
            height: 100%;
            padding: 0;
            transition: all 0.3s;

            .klimax-climatecard-title {
                color: $klimax-orange;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-size: 26px;

                span {
                    color: $klimax-black;
                    letter-spacing: 0.6px;
                    font-family: "Flanders Art Serif Bold";
                }

                i {
                    font-size: 60px;
                    color: $klimax-orange;
                    transition: all 0.3s;
                    padding: 30px 0;
                    transform: scale(1);
                    transition: all 0.5s;
                }
            }

            &:before {
                display: none;
            }
        }

        &:hover {
            transform: scale(0.98);
            transition: all 0.5s;

            .klimax-climatecard-content {
                border-color: $klimax-orange;
                transition: all 0.3s;

                .klimax-climatecard-title {
                    i {
                        color: $klimax-orange;
                        transform: scale(1.2);
                        transition: all 0.5s;
                    }
                }
            }
        }
    }

    &--placeholder {
        background: $klimax-grey !important;
        box-shadow: none;
    }

    &-wrapper {
        overflow: hidden;
        height: 100%;
        position: relative;
    }

    &-content {
        background: $klimax-orange;
        padding: 20px 110px 20px 25px;
        position: absolute;
        bottom: 0;
        z-index: 2;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;

        &:before {
            content: "";
            display: block;
            height: 80px;
            background: $klimax-orange;
            width: calc(100% + 12px);
            left: -6px;
            position: absolute;
            top: -24px;
            transform: rotate(5deg);
            z-index: -1;
        }
    }

    &-more-info {
        color: $klimax-white;

        &:hover {
            color: $klimax-white;
            text-decoration: underline !important;
        }
    }

    &--theme {
        // background: $klimax-card-blue;

        .klimax-climatecard-label {
            color: $klimax-card-blue;
        }

        .klimax-climatecard-content {
            background: $klimax-card-blue;

            &:before {
                background: $klimax-card-blue;
            }
        }

        .klimax-climatecard__face--back {
            background: $klimax-card-blue;

            &:after {
                background: linear-gradient(to bottom,
                        rgba($klimax-card-blue, 0),
                        rgba($klimax-card-blue, 0.95) 80%);
            }
        }
    }

    &--goal {
        // background: $klimax-black;

        .klimax-climatecard-pic {
            background: url('https://oetang.be/klimax/assets/images/inspiratie/competenties/Klimax_doelen.jpg');
        }

        .klimax-climatecard-content {
            background: $klimax-black;

            &:before {
                background: $klimax-black;
            }
        }

        .klimax-climatecard-baseline {
            display: -webkit-box !important;
        }

        .klimax-climatecard__face--back {
            background: $klimax-black;

            .klimax-goals-list {
                display: block;
                position: relative;
                margin-top: 60px;

                &__item {
                    width: fit-content;

                    span {
                        margin-right: 8px;
                    }
                }
            }
        }

        .klimax-goals-list {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;
            position: absolute;
            bottom: 20px;

            &__item {
                align-items: center;
                display: flex;
                width: 30px;
                justify-content: space-between;
                margin-right: 20px;
                padding: 0;

                &:before {
                    display: none !important;
                }

                span {
                    background: $klimax-orange;
                    border-radius: 50%;
                    height: 13px;
                    width: 13px;
                    max-height: 13px;
                    max-width: 13px;
                    display: block;
                }

                &--orange {
                    span {
                        background: $klimax-method-orange;
                    }
                }

                &--yellow {
                    span {
                        background: $klimax-method-yellow;
                    }
                }

                &--red {
                    span {
                        background: $klimax-method-red;
                    }
                }

                &-counter {
                    background: $klimax-white;
                    width: 14px;
                    height: 17px;
                    color: $klimax-black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 600;
                    margin-left: 15px;
                }
            }
        }
    }

    &--topic {
        &-oorzaken {
            // background: $klimax-topic-darkgreen;

            .klimax-climatecard-content,
            .klimax-climatecard-content:before {
                background: $klimax-topic-darkgreen;
            }

            .klimax-climatecard__face--back {
                background: $klimax-topic-darkgreen;

                &:before {
                    background: linear-gradient(to top,
                            rgba($klimax-topic-darkgreen, 0),
                            rgba(rgba($klimax-topic-darkgreen, 0.95), 0.95) 80%);
                }

                &:after {
                    background: linear-gradient(to bottom,
                            rgba($klimax-topic-darkgreen, 0),
                            rgba($klimax-topic-darkgreen, 0.95) 80%);
                }
            }

            .external-links {
                li {
                    a {
                        text-decoration: underline !important;
                        color: $klimax-white !important;

                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }

        &-gevolgen {
            // background: $klimax-topic-midgreen;

            .klimax-climatecard-content,
            .klimax-climatecard-content:before {
                background: $klimax-topic-midgreen;
            }

            .klimax-climatecard__face--back {
                background: $klimax-topic-midgreen;

                &:before {
                    background: linear-gradient(to top,
                            rgba($klimax-topic-midgreen, 0),
                            rgba(rgba($klimax-topic-midgreen, 0.95), 0.95) 80%);
                }

                &:after {
                    background: linear-gradient(to bottom,
                            rgba($klimax-topic-midgreen, 0),
                            rgba($klimax-topic-midgreen, 0.95) 80%);
                }
            }

            .external-links {
                li {
                    a {
                        text-decoration: underline !important;
                        color: $klimax-white !important;

                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }

        &-oplossingen {
            // background: $klimax-topic-lightgreen;

            .klimax-climatecard-content,
            .klimax-climatecard-content:before {
                background: $klimax-topic-lightgreen;
            }

            .klimax-climatecard__face--back {
                background: $klimax-topic-lightgreen;

                &:before {
                    background: linear-gradient(to top,
                            rgba($klimax-topic-lightgreen, 0),
                            rgba(rgba($klimax-topic-lightgreen, 0.95), 0.95) 80%);
                }

                &:after {
                    background: linear-gradient(to bottom,
                            rgba($klimax-topic-lightgreen, 0),
                            rgba($klimax-topic-lightgreen, 0.95) 80%);
                }
            }

            .external-links {
                li {
                    a {
                        text-decoration: underline !important;
                        color: $klimax-white !important;

                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }
    }

    &--method {
        &-yellow {
            // background: $klimax-card-yellow;

            .klimax-climatecard-content,
            .klimax-climatecard-content:before {
                background: $klimax-method-yellow;
            }

            .klimax-climatecard__face--back {
                background: $klimax-method-yellow;

                &:before {
                    background: linear-gradient(to top,
                            rgba($klimax-method-yellow, 0),
                            rgba(rgba($klimax-method-yellow, 0.95), 0.95) 80%);
                }

                &:after {
                    background: linear-gradient(to bottom,
                            rgba($klimax-method-yellow, 0),
                            rgba($klimax-method-yellow, 0.95) 80%);
                }
            }
        }

        &-orange {
            // background: $klimax-orange;

            .klimax-climatecard-content,
            .klimax-climatecard-content:before {
                background: $klimax-method-orange;
            }

            .klimax-climatecard__face--back {
                background: $klimax-method-orange;

                &:before {
                    background: linear-gradient(to top,
                            rgba($klimax-method-orange, 0),
                            rgba(rgba($klimax-method-orange, 0.95), 0.95) 80%);
                }

                &:after {
                    background: linear-gradient(to bottom,
                            rgba($klimax-method-orange, 0),
                            rgba($klimax-method-orange, 0.95) 80%);
                }
            }
        }

        &-red {
            // background: $klimax-method-red;

            .klimax-climatecard-content,
            .klimax-climatecard-content:before {
                background: $klimax-method-red;
            }

            .klimax-climatecard__face--back {
                background: $klimax-method-red;

                &:before {
                    background: linear-gradient(to top,
                            rgba($klimax-method-red, 0),
                            rgba(rgba($klimax-method-red, 0.95), 0.95) 80%);
                }

                &:after {
                    background: linear-gradient(to bottom,
                            rgba($klimax-method-red, 0),
                            rgba($klimax-method-red, 0.95) 80%);
                }
            }
        }
    }

    &-pic {
        display: block;
        height: calc(100% - 120px);
        background-size: cover !important;
        background-position: center;
    }

    &-flip {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 38px;
        height: 38px;
        background: $klimax-white;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        z-index: 3;

        &:hover {
            background: $klimax-black;
            transition: all 0.3s;
            cursor: pointer;

            i {
                color: $klimax-white;
            }
        }

        i {
            font-size: 18px;
            display: flex;
        }
    }

    &-label {
        overflow: hidden;
        position: absolute;
        top: -44px;
        
        
        > div {
            align-items: flex-start;
            background: $klimax-white;
            color: $klimax-black;
            display: flex;
            font-family: "Flanders Art Sans Bold";
            font-size: 16px;
            max-height: 24px;
            justify-content: center;
            padding: 0 0 0 5px;
            
            
            width: fit-content;
            text-transform: lowercase;
            text-overflow: ellipsis;
            margin-right: 25px;
    
            span {
                // display: none;
                margin-right: 4px;
                font-family: "Flanders Art Sans Regular";
            }

            &:after {
                content: "";
                width: 50px;
                height: 50px;
                -webkit-transform: skew(20deg);
                -moz-transform: skew(-20deg);
                -o-transform: skew(-20deg);
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 10px;
                transition: all 0.3s;
                background: white;
                z-index: -1;
            }
        }

       
        
    }

    &-title {
        font-family: "Flanders Art Sans Bold";
        font-size: 26px;
        line-height: 30px;
        margin-top: -20px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        hyphens: auto;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding-bottom: 2px;
    }

    &-baseline {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        hyphens: auto;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 18px;
        margin-top: 5px;
    }

    &-pin {
        position: absolute !important;
        right: 20px;
        bottom: 20px;
        transition: all 0.3s;
        margin: 0;

        &:hover {
            transition: all 0.3s;
            cursor: pointer;
        }

        .dropdown-toggle {
            background: none !important;
            border: none !important;
            padding: 0;

            &:after {
                display: none;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: transparent !important;
                border: none;
                outline: none;
                box-shadow: none !important;
            }

            i {
                display: flex;
                font-size: 22px;
            }
        }

        .dropdown-menu {
            padding: 0;
            right: -10px !important;
            margin-top: -10px !important;
            transform: translate(0px, -34px) !important;
            border: none;
            min-width: initial !important;
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            position: relative;
            transition: all 0.3s;

            i {
                color: $klimax-orange;
                position: relative;
                top: 2px;
                margin-right: 8px;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid $klimax-white;
                position: absolute;
                bottom: -7px;
                right: 12px;
            }

            &:hover {
                background: none;
                color: $klimax-orange;
                transition: all 0.3s;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .klimax-climatecard {
        width: calc(33.333333% - 10px) !important;
    }
}

@media screen and (max-width: 1200px) {
    .klimax-climatecard {
        width: calc(50% - 8px);
    }
}

@media screen and (max-width: 992px) {
    .klimax-inspiration-results {
        .klimax-climatecards {
            .klimax-climatecard {
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .klimax-climatecards {
        justify-content: flex-start;
    }

    .klimax-climatecard {
        width: 100%;
    }

    .klimax-climatecard--placeholder {
        &:not(:last-child) {
            display: none;
        }
    }
}
.cookie {
  -webkit-box-shadow: -1px 0px 36px 7px rgba(0, 0, 0, 0.36);
  background: #F6F6F3;
  border-radius: 6px;
  bottom: 30px;
  box-shadow: -1px 0px 36px 7px rgba(0, 0, 0, 0.36);
  color: $klimax-black;
  left: 30px;
  padding: 30px 30px 20px 30px;
  position: fixed;
  width: 70%;
  z-index: 9999;
  display: flex;
  align-items: flex-start;

  &__img {
    width: 180px;
    margin-right: 30px;

    img {
       height: 100%;
       width: 100%;
    }
  }

  h3 {
    margin-top: 0;
    font-size: 24px;
    font-family: 'Flanders Art Sans Bold';
  }

  p {
    color: $klimax-black;
    font-size: 18px;
    font-family: 'Flanders Art Sans Regular';
  }

  a {
    color: $klimax-orange;
    font-family: 'Flanders Art Sans Bold';
    text-decoration: underline !important;

    &:hover {
        color: $klimax-orange;
        text-decoration: none !important;
    }
  }

  &__buttons {
    display: flex;
  }

  .klimax-button--inverted {
    margin: 0 15px 10px 0;
    cursor: pointer;
  }
}

// .privacy-policy {
//   padding: 40px 8.33%;

//   &__intro {
//     background: $baseGreen-primary;
//     border-radius: 8px;
//     color: $baseWhite;
//     margin-bottom: 50px;
//     padding: 80px 80px 40px 80px;

//     p {
//       color: $baseWhite;
//       margin-bottom: 0;
//     }

//     h3 {
//       color: $baseGreen-secondary;
//       margin-top: 0;
//     }
//   }
// }

// .CookieConsent {
//   background: none !important;
// }

// #rcc-confirm-button {
//   background-color: $baseGreen-secondary !important;
//   border-radius: 6px !important;
//   border: none;
//   bottom: 50px;
//   color: $baseWhite !important;
//   font-size: 16px;
//   left: 55px;
//   padding: 20px 28px 20px 25px !important;
//   position: absolute;
//   text-decoration: none;
//   transition: all 0.3s;
// }

@media screen and (max-width: $breakpoint-m) {
  .privacy-policy {
    &__intro {
      padding: 80px 40px;
    }
  }
}

@media screen and (max-width: $breakpoint-m) {
  .cookie {
    width: 90%;
    .cookie__img {
        display: none;
    }
  }

  #rcc-confirm-button {
    left: 35px;
    bottom: 30px;
  }
}

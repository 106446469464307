.klimax-print {
    padding: 60px 0;

    &-wrapper {
        max-width: 960px;
        margin: 0 auto;
        position: relative;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    h1 {
        font-size: 44px;
        font-family: 'Flanders Art Sans Bold';
        color: $klimax-orange;
    }

    h2 {
        font-size: 40px;
        font-family: 'Flanders Art Sans Bold';
        margin: 0;
    }

    h3 {
        font-size: 30px;
        font-family: 'Flanders Art Sans Bold';
        color: $klimax-orange;
    }

    &__intro {
        margin-top: 50px;
    }

    &__legende {
        display: flex;

        ul {
            margin: 0;
            margin-top: 60px;
            padding: 0;
            list-style: none;

            li {
                font-family: 'Flanders Art Sans Bold';
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                width: 250px;
                position: relative;

                .klimax-print__count {
                    position: absolute;
                    left: 200px;
                    display: flex;

                    span {
                        align-items: center;
                        background: pink;
                        border: 2px solid pink;
                        border-radius: 50%;
                        color: $klimax-white;
                        display: flex;
                        font-size: 12px;
                        height: 20px;
                        justify-content: center;
                        margin-left: 8px;
                        width: 20px;
                    }

                    &--theme {
                        background: $klimax-card-blue !important;
                        border-color: $klimax-card-blue !important;
                    }

                    &--topic {
                        &-oorzaken {
                            background: $klimax-topic-darkgreen !important;
                            border-color: $klimax-topic-darkgreen !important;
                        }

                        &-gevolgen {
                            background: $klimax-topic-midgreen !important;
                            border-color: $klimax-topic-midgreen !important;
                            ;
                        }

                        &-oplossingen {
                            background: $klimax-topic-lightgreen !important;
                            border-color: $klimax-topic-lightgreen !important;
                        }
                    }

                    &--method {
                        &-yellow {
                            background: $klimax-method-yellow !important;
                            border-color: $klimax-method-yellow !important;
                        }

                        &-orange {
                            background: $klimax-method-orange !important;
                            border-color: $klimax-method-orange !important;
                        }

                        &-red {
                            background: $klimax-method-red !important;
                            border-color: $klimax-method-red !important;
                        }
                    }

                    &--goal {
                        &-yellow {
                            background: $klimax-method-yellow !important;
                            border-color: $klimax-method-yellow !important;
                            ;
                        }

                        &-orange {
                            background: $klimax-method-orange !important;
                            border-color: $klimax-method-orange !important;
                            ;
                        }

                        &-red {
                            background: $klimax-method-red !important;
                            border-color: $klimax-method-red !important;
                        }
                    }
                }


            }
        }
    }

    &__svg {
        width: 263px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &-block {
        margin-bottom: 30px;
        border-bottom: 2px solid #f4f4f4;

        h3 {
            margin-bottom: 30px;
        }

        &--theme {
            h3 {
                color: $klimax-card-blue;
            }
        }

        &--topic {
            h3 {
                color: $klimax-card-green;
            }
        }

        &--goal {
            h3 {
                color: $klimax-black;
            }

            .klimax-printcard {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            margin-top: 30px;
        }
    }
}

.klimax-printcard {
    display: block;
    width: 100%;
    border: 2px solid $klimax-black;
    margin-bottom: 40px;
    height: 100%;
    overflow: hidden;
    break-inside: avoid;

    &--goal {
        break-inside: initial !important;
        border: none;

        .klimax-goals {
            margin: 0;

            .klimax-goal {
                &--yellow {
                     ul > li:before {
                        background: $klimax-method-yellow !important;
                        border-color: $klimax-method-yellow !important;
                    }
                }

                &--orange {
                    ul > li:before {
                        background: $klimax-method-orange !important;
                        border-color: $klimax-method-orange !important;
                    }

                }

                &--red {
                    ul > li:before {
                        background: $klimax-method-red !important;
                        border-color: $klimax-method-red !important;
                    }
                }

                >div {
                    margin-bottom: 30px;

                    >div {
                        font-family: 'Flanders Art Sans Bold';

                        span {
                            font-family: 'Flanders Art Sans Regular';
                            color: $klimax-black;
                        }
                    }

                    >ul {
                        list-style: none;
                        padding-left: 20px;
                        margin: 5px 0 0 0;

                        >li {
                            padding-left: 5px;
                            margin-bottom: 5px;

                            &:before {
                                display: inline-block;
                                content: "";
                                background: $klimax-black;
                                height: 12px;
                                width: 12px;
                                border-radius: 50%;
                                margin-right: 10px;
                                margin-left: -22px;
                                position: relative;
                                left: -2px;
                                border: 6px solid $klimax-black;
                            }
                        }
                    }
                }


            }
        }
    }

    &-wrapper {
        display: flex;
        position: relative;
    }

    &-pic {
        display: block;
        width: 250px;
        left: -30px;
        top: -30px;
        bottom: -30px;
        z-index: -2;
        object-fit: cover;
    }

    &-content {
        position: relative;
        padding: 30px 30px;

        &:before {
            content: "";
            width: 100px;
            height: 100%;
            -webkit-transform: skew(10deg);
            -moz-transform: skew(10deg);
            -o-transform: skew(10deg);
            overflow: hidden;
            position: absolute;
            top: 0;
            left: -35px;
            transition: all 0.3s;
            background: $klimax-white;
            z-index: -1;
        }
    }

    &-title {
        font-family: "Flanders Art Sans Bold";
        font-size: 30px;
        line-height: 30px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        hyphens: auto;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding-bottom: 4px;
        margin-bottom: 5px;
    }

    &-label {
        font-family: "Flanders Art Sans Bold";
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        hyphens: auto;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 18px;
        margin-bottom: 15px;
    }

    &-baseline {
        font-family: "Flanders Art Sans Bold";
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        hyphens: auto;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 18px;
        margin-bottom: 15px;
    }

    p {
        margin: 0;
    }

    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        margin: 0;
        padding: 0;
        padding-left: 20px;
    }

    ol li {
        counter-increment: my-awesome-counter;
        position: relative;
        margin-bottom: 10px;
    }

    ol li::before {
        content: counter(my-awesome-counter) " ";
        color: $klimax-black;
        font-family: 'Flanders Art Sans Bold';
        position: absolute;
        left: -20px;
    }

    &--theme {
        border-color: $klimax-card-blue;

        .klimax-printcard-title {
            color: $klimax-card-blue;
        }
    }

    &--topic {
        &-oorzaken {
            border-color: $klimax-topic-darkgreen;

            .klimax-printcard-title {
                color: $klimax-topic-darkgreen;
            }
        }

        &-gevolgen {
            border-color: $klimax-topic-midgreen;

            .klimax-printcard-title {
                color: $klimax-topic-midgreen;
            }
        }

        &-oplossingen {
            border-color: $klimax-topic-lightgreen;

            .klimax-printcard-title {
                color: $klimax-topic-lightgreen;
            }
        }
    }

    &--method {
        &-yellow {
            border-color: $klimax-method-yellow;

            .klimax-printcard-title {
                color: $klimax-method-yellow;
            }
        }

        &-orange {
            border-color: $klimax-method-orange;

            .klimax-printcard-title {
                color: $klimax-method-orange;
            }
        }

        &-red {
            border-color: $klimax-method-red;

            .klimax-printcard-title {
                color: $klimax-method-red;
            }
        }
    }
}

@media print {
    .klimax-print__buttons {
        display: none !important;
    }

    .klimax-print__intro {
        margin-top: 0px;
    }

    .klimax-printcard-wrapper {
        flex-direction: column;
    }

    .klimax-printcard-label {
        font-size: 10px;
        margin-bottom: 10px;
    }

    .klimax-printcard-title,
    .klimax-printcard-baseline {
        font-size: 80% !important;
        margin-bottom: 5px !important;
        line-height: 80% !important;
    }

    .klimax-printcard p {
        font-size: 80% !important;
    }

    .klimax-printcard-pic {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        right: 15px !important;
        left: inherit !important;
        top: 15px !important;
        bottom: 0;
        position: absolute !important;
        margin-bottom: 15px !important;
    }

    .klimax-printcard-content {
        padding: 15px !important;
    }
}
.klimax-cardboard {
    .klimax-climatecard-baseline {
        display: none;
    }
    
    .klimax-dropdown {
        display: block;
    }

    .klimax-unpin {
        display: none;
    }

    .klimax-climatecard--goal {
        .klimax-climatecard-flip {
            i {
                transform: rotate(-45deg);
                color: $klimax-black;
                transition: all 0.3s;
            }

            &:hover {
                i {
                    color: $klimax-white;
                    transition: all 0.3s;
                }
            }
        }
        
        
    }
}